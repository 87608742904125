//#region react import
import { Document, Font, Page, StyleSheet, View } from "@react-pdf/renderer";
import React from "react";
//#endregion

//#region components import
import PdfDriverProfile from "../PdfDriverProfile";
import PdfHeader from "../PdfHeader";
import MachineResult from "./MachineResult.component";
//#endregion

//#region constants import
import OpenDyslexicRegular from "../OpenDyslexic-Regular.ttf";
import PdfFooter from "../PdfFooter";
import RobotoBold from "../Roboto-Bold.ttf";
import RobotoItalic from "../Roboto-Italic.ttf";
import Roboto from "../Roboto-Medium.ttf";

//#endregion

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    fontFamily: "Roboto",
    paddingTop: 90,
    paddingBottom: 80,
    fontSize: 12,
  },
  content: {
    flexDirection: "column",
    gap: "30px",
    paddingHorizontal: 35,
  },
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: Roboto,
    },
    {
      src: RobotoBold,
      fontWeight: "bold",
    },
    {
      src: RobotoItalic,
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});
Font.register({
  family: "OpenDyslexic",
  fonts: [
    {
      src: OpenDyslexicRegular,
    },
  ],
});

// Create Document Component
const CustomPdf = ({
  pdfData,
  accessStart,
  accessEnd,
  sessionDates,
  translations,
  notationType,
  customFields,
  pdfTemplate,
  image,
  pdfInfos,
}) => {
  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
      >
        <PdfHeader
          firstName={pdfData.firstName}
          lastName={pdfData.lastName}
          center={pdfData.center}
          translations={translations}
          accessStart={accessStart}
          accessEnd={accessEnd}
          sessionDates={sessionDates}
          pdfTemplate={pdfTemplate}
          image={image}
          pdfInfos={pdfInfos}
        />
        <PdfFooter pdfInfos={pdfInfos} />
        <View style={styles.content}>
          <PdfDriverProfile
            pdfTemplate={pdfTemplate}
            pdfData={pdfData}
            translations={translations}
            accessStart={accessStart}
            accessEnd={accessEnd}
            sessionDates={sessionDates}
            customFields={customFields}
            studentResult={pdfData}
          />
          {pdfData?.machineResults &&
            pdfData.machineResults.length > 0 &&
            pdfData.machineResults.map((machineResult, index) => (
              <MachineResult
                pdfTemplate={pdfTemplate}
                translations={translations}
                machineResult={machineResult}
                notationType={notationType}
                isNeedToBreak={index > 0 || pdfTemplate.showGenericSkills || pdfTemplate.showCalendar}
              />
            ))}
        </View>
      </Page>
    </Document>
  );
};

export default CustomPdf;
