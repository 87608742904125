//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { hasTwoWords, isNullOrSpaces } from "../../Helper/StringUtils";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region component import
import GstaInput from "../GstaInput/GstaInput.component";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../Constants/Translations";
import { INPUT_TEXT, INPUT_TEXT_AREA } from "../GstaInput/GstaInput.constants";
//#endregion

//#region styles import
import "./QuestionnaryModal.style.scss";
//#endregion

const QuestionnaryModalContent = ({
  isLocal,
  trainerName,
  setTrainerName,
  softwares,
  setSoftwares,
  description,
  setDescription,
  alreadySended,
}) => {
  //#region hook
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const getTrainerNameErrorMessage = () => {
    if (isNullOrSpaces(trainerName)) {
      return getTranslations(TRANSLATIONS.FIELD_IS_REQUIRED, translations);
    }
    if (!hasTwoWords(trainerName)) {
      return getTranslations(TRANSLATIONS.QUESTIONNARY_TRAINER_NAME_FORMAT_ERROR, translations);
    }
    // Prevent default error message span size
    return true;
  };

  const getSoftwareErrorMessage = () => {
    if (isNullOrSpaces(softwares)) {
      return getTranslations(TRANSLATIONS.FIELD_IS_REQUIRED, translations);
    }
    if (softwares.length <= 2) {
      return getTranslations(TRANSLATIONS.QUESTIONNARY_SOFTWARE_FORMAT_ERROR, translations);
    }
    // Prevent default error message span size
    return true;
  };
  //#endregion

  return (
    <section className="questionnary-modal-content">
      <span className="hotline-infos">{getTranslations(TRANSLATIONS.QUESTIONNARY_INFO_HOTLINE, translations)}</span>
      {isLocal && (
        <GstaInput
          id="questionnary-mail"
          label={getTranslations(TRANSLATIONS.QUESTIONNARY_YOUR_NAME, translations)}
          placeholder={getTranslations(TRANSLATIONS.QUESTIONNARY_YOUR_NAME_PLACEHOLDER, translations)}
          value={trainerName}
          setValue={setTrainerName}
          isValid={!alreadySended || hasTwoWords(trainerName)}
          type={INPUT_TEXT}
          appearanceClass="gsta-input--black input"
          errorMessage={getTrainerNameErrorMessage()}
        />
      )}

      <GstaInput
        id="questionnary-software"
        label={getTranslations(TRANSLATIONS.QUESTIONNARY_SOFTWARE, translations)}
        placeholder={getTranslations(TRANSLATIONS.QUESTIONNARY_SOFTWARE_PLACEHOLDER, translations)}
        value={softwares}
        setValue={setSoftwares}
        isValid={!alreadySended || softwares.length > 2}
        type={INPUT_TEXT}
        appearanceClass="gsta-input--black input"
        errorMessage={getSoftwareErrorMessage()}
      />

      <GstaInput
        id="questionnary-description"
        label={getTranslations(TRANSLATIONS.QUESTIONNARY_DESCRIPTION, translations)}
        placeholder={getTranslations(TRANSLATIONS.QUESTIONNARY_DESCRIPTION_PLACEHOLDER, translations)}
        value={description}
        setValue={setDescription}
        isValid={!alreadySended || !isNullOrSpaces(description)}
        type={INPUT_TEXT_AREA}
        appearanceClass="gsta-input--black input"
        errorMessage={getTranslations(TRANSLATIONS.FIELD_IS_REQUIRED, translations)}
      />
      <span className="required-field">{getTranslations(TRANSLATIONS.REQUIRED_FIELD, translations)}</span>
    </section>
  );
};

export default QuestionnaryModalContent;
