//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosCheckBox from "../AcreosCheckBox/AcreosCheckBox.component";
import GstaInput from "../GstaInput/GstaInput.component";
//#endregion

//#region functions import
import { copyObject } from "../../Helper/CopyObject";
import { SetDataTestId } from "../../Helper/DataTestId";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { INPUT_TEXT } from "../GstaInput/GstaInput.constants";
//#endregion

//#region style import
import { TRANSLATIONS } from "../../Constants/Translations";
import "./CreateCustomPdfStep1.style.scss";
//#endregion

/**
 * Step one of the creation of a custom pdf template.
 */

const CreateCustomPdfStep1 = ({ pdfTemplate, setPdfTemplate, nameIsCorrect }) => {
  //#region useState
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  //#region functions
  const setTemplateName = (currentInputValue) => {
    let templateCopied = copyObject(pdfTemplate);
    templateCopied.name = currentInputValue;
    setPdfTemplate(templateCopied);
  };

  const setTemplateIsDefault = () => {
    let templateCopied = copyObject(pdfTemplate);
    templateCopied.isDefault = !templateCopied.isDefault;
    setPdfTemplate(templateCopied);
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <section className="create-pdf-template-step-one">
      <header>
        <h1>
          {`${getTranslations("step_progress_step", translations)} 1 - ${getTranslations(
            "training_layout_detail_general_informations",
            translations
          )}`}
        </h1>
        <span>{getTranslations(TRANSLATIONS.REQUIRED_FIELD, translations)}</span>
      </header>
      <GstaInput
        label={`${getTranslations("model_name", translations)} *`}
        value={pdfTemplate?.name}
        setValue={(currentInputValue) => setTemplateName(currentInputValue)}
        placeholder={getTranslations("placeholder_pdf_template_name", translations)}
        type={INPUT_TEXT}
        isValid={nameIsCorrect}
        appearanceClass={"gsta-input_column gsta-input--black"}
        errorMessage={nameIsCorrect ? "" : "error_pdf_template_name"}
      />
      <div
        onClick={setTemplateIsDefault}
        className="use-default"
      >
        <AcreosCheckBox
          dataTestId={SetDataTestId("use_as_default_template")}
          checked={pdfTemplate?.isDefault}
          onChange={setTemplateIsDefault}
        />
        <span>{getTranslations("default_use", translations)}</span>
      </div>
    </section>
  );
};
export default CreateCustomPdfStep1;
