//#region react import
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
//#endregion

//#region components import
import MachineHistory from "./MachineHistory.component";
import MachineSkills from "./MachineSkills.component";
import MachineTrainingResult from "./MachineTrainingResult.component";
//#endregion

//#region functions import
import { getNotePdf } from "../../../Helper/NoteConverter";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
import { getIconPath, getNoteClass } from "./CustomPdf.functions";
//#endregion

//#region constants import
import { COLOR, FONT_SIZE } from "./CustomPdf.constants";
//#endregion

const MachineResult = ({ pdfTemplate, machineResult, translations, notationType, isNeedToBreak }) => {
  //#region styles
  const styles = StyleSheet.create({
    machineResult: {
      width: "100%",
      flexDirection: "column",
      borderColor: COLOR.orange,
      borderStyle: "solid",
      borderWidth: "2px",
    },
    mainTitle: {
      width: "100%",
      backgroundColor: COLOR.orange,
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      fontSize: FONT_SIZE.mainTitle,
      paddingVertical: "5px",
      flexDirection: "row",
      gap: "6px",
    },
    summaryView: {
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
    },
    timeSimulation: {
      width: "40%",
      margin: "5px",
      padding: "8px",
      paddingLeft: "0px",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLOR.darkGrey,
      color: COLOR.textColorInversed,
      textAlign: "center",
    },
    totalTimeSimulation: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    totalTimeSimulationText: {
      fontWeight: "bold",
    },
    totalTimeSimulationValue: {
      fontSize: "17px",
      marginTop: "5px",
      marginBottom: "5px",
      color: COLOR.orange,
    },
    cursusAndFreeTimeText: {
      fontStyle: "italic",
    },
    summaryImage: {
      width: "15px",
      marginRight: "6px",
      color: COLOR.textColorInversed,
    },
    averageText: {
      width: "40%",
      margin: "5px",
      padding: "8px",
      paddingLeft: "0px",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLOR.darkGrey,
      color: COLOR.textColorInversed,
      textAlign: "center",
    },
    titleImage: {
      width: "40px",
    },
  });
  return (
    <View
      style={styles.machineResult}
      break={isNeedToBreak}
    >
      <View style={styles.mainTitle}>
        <Image
          style={styles.titleImage}
          src={getIconPath(machineResult?.machine?.code)}
        />
        <View>
          <Text>{getTranslations(machineResult?.machine.languageKey, translations)}</Text>
        </View>
      </View>
      <View style={styles.summaryView}>
        <View style={styles.timeSimulation}>
          <View style={styles.totalTimeSimulation}>
            <Image
              style={styles.summaryImage}
              source={getIconPath("hourglass")}
            />
            <Text style={styles.totalTimeSimulationText}>
              {getTranslations("student_detail_training_machine_total_time_elapsed", translations)}
            </Text>
          </View>
          <Text style={styles.totalTimeSimulationValue}>
            {formatSecondToStringTime(machineResult.totalSecondElapsed, translations, FORMAT_TIMESTRING.HHMM_IGNOR_S)}
          </Text>
          <Text style={styles.cursusAndFreeTimeText}>{`${getTranslations(
            "cursus_mod",
            translations
          )} : ${formatSecondToStringTime(
            machineResult.totalSecondElapsed,
            translations,
            FORMAT_TIMESTRING.HHMM_IGNOR_S
          )}`}</Text>
          <Text style={styles.cursusAndFreeTimeText}>{`${getTranslations(
            "free_mode",
            translations
          )} : ${formatSecondToStringTime(
            machineResult.freeTotalSecondElapsed,
            translations,
            FORMAT_TIMESTRING.HHMM_IGNOR_S
          )}`}</Text>
        </View>
        <View style={styles.timeSimulation}>
          <View style={styles.totalTimeSimulation}>
            <Image
              style={styles.summaryImage}
              source={getIconPath("Gauge")}
            />
            <Text style={styles.totalTimeSimulationText}>{getTranslations("general_average", translations)}</Text>
          </View>
          <Text style={getNoteClass(styles.totalTimeSimulationValue, machineResult?.averagePercent, true)}>
            {getNotePdf(machineResult?.averagePercent, notationType)}
          </Text>
        </View>
      </View>
      {pdfTemplate.showSkills && (
        <MachineSkills
          machineSkills={machineResult?.machineSkills}
          translations={translations}
        />
      )}
      {pdfTemplate.showTrainingSteps && (
        <MachineTrainingResult
          trainingResults={machineResult?.trainingResults}
          freeTotalSecondElapsed={machineResult.freeTotalSecondElapsed}
          translations={translations}
          notationType={notationType}
        />
      )}
      {machineResult.trainingHistories.length > 0 && (
        <MachineHistory
          translations={translations}
          trainingHistories={machineResult.trainingHistories}
          notationType={notationType}
        />
      )}
    </View>
  );
};

export default MachineResult;
