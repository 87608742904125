//#region react import
import { StyleSheet, Text, View } from "@react-pdf/renderer";
//#endregion

//#region custom import
import PdfTitle, { SUBTITLE } from "../Pdf/PdfTitle";
//#endregion

//#region functions import
import { getNotePdf } from "../../Helper/NoteConverter";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../Helper/TimeConverter";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { COLOR, NO_RESULT } from "./GroupedPdf.Constants";
//#endregion

const styles = StyleSheet.create({
  TableMainTitle: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: COLOR.PRIMARY,
    alignItems: "center",
    fontWeight: "bold",
    color: COLOR.TEXT_COLOR_PRIMARY,
    paddingVertical: "6px",
    marginVertical: "6px",
  },
  flexAllStudent: {
    display: "flex",
    gap: "20px",
  },
  studentTitle: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: COLOR.GREY_DARK,
    alignItems: "center",
    fontWeight: "bold",
    paddingVertical: "4px",
    color: COLOR.TEXT_PRIMARY,
  },
  studentSubscriptionTitle: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: COLOR.GREY,
    alignItems: "center",
    fontWeight: "bold",
    paddingVertical: "6px",
    color: COLOR.TEXT_PRIMARY,
  },
  studentContent: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: COLOR.PRIMARY,
  },
  tableCell: {
    display: "flex",
    flexDirection: "row",
    width: "25%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    paddingVertical: "2px",
  },
  tableCellFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  machineNote: {
    backgroundColor: COLOR.GREY,
    borderRadius: "3px",
    paddingVertical: "3px",
    paddingHorizontal: "4px",
    color: COLOR.TEXT_PRIMARY,
  },
  subscriptionRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  machineContainer: {
    marginTop: "36px",
  },
  whiteText: {
    color: COLOR.TEXT_PRIMARY,
  },
});

const getStudentSimulationTime = (student, translations, format) => {
  let timeSimulation = 0;
  student.subscriptions.forEach((subscription) => (timeSimulation += subscription.totalSecondsElapsed));
  return formatSecondToStringTime(timeSimulation, translations, format);
};

const getStudentAverage = (student) => {
  let average = 0;
  let subscriptionWithResult = 0;
  student.subscriptions.forEach((subscription) => {
    if (subscription.averagePercent) {
      average += subscription.averagePercent;
      subscriptionWithResult++;
    }
  });
  if (subscriptionWithResult === 0) return NO_RESULT;
  else return average / subscriptionWithResult;
};

const GroupedPdfContent = ({ groupedPdfData, notationType, translations }) => {
  const renderSubscriptionResult = (student) => {
    let orangeBackGround = true;
    return student.subscriptions.map((subscription) => {
      orangeBackGround = !orangeBackGround;
      return (
        <View
          key={`${student.firstname}-${student.lastName}-${subscription.trainingName}`}
          style={{ ...styles.subscriptionRow, backgroundColor: orangeBackGround ? "#F6DEBB" : "transparent" }}
        >
          <Text style={styles.tableCell}>{getTranslations(subscription.trainingName, translations)}</Text>
          <Text style={styles.tableCell}>{`${subscription.currentStepOrder}/${subscription.stepCount}`}</Text>
          <Text style={styles.tableCell}>
            {formatSecondToStringTime(subscription.totalSecondsElapsed, translations, FORMAT_TIMESTRING.HHMM)}
          </Text>
          <View style={styles.tableCell}>
            <Text
              style={{
                ...styles.machineNote,
                backgroundColor: getNoteColor(subscription.averagePercent),
              }}
            >
              {getTranslations(getNotePdf(subscription.averagePercent ?? NO_RESULT, notationType), translations)}
            </Text>
          </View>
        </View>
      );
    });
  };

  const getNoteColor = (note) => {
    if (!note || note === NO_RESULT) return COLOR.GREY;
    if (note <= 35) return COLOR.INVALID;
    else if (35 < note && note <= 75) return COLOR.PRIMARY;
    else return COLOR.VALID;
  };

  return (
    <View>
      {groupedPdfData.map((machine, index) => (
        <View
          key={machine.code}
          style={styles.machineContainer}
          break={index > 0}
        >
          <PdfTitle
            type={SUBTITLE}
            label={getTranslations(machine.languageKey, translations)}
            image={`${process.env.PUBLIC_URL}/Images/icons/${machine.code}.png`}
          />
          <View
            style={styles.TableMainTitle}
            wrap={false}
          >
            <Text style={{ ...styles.tableCell, ...styles.whiteText }}>
              {getTranslations("common_name_lower", translations)}
            </Text>
            <Text style={{ ...styles.tableCell, ...styles.whiteText }}>
              {getTranslations("common_first_name", translations)}
            </Text>
            <Text style={{ ...styles.tableCell, ...styles.whiteText }}>
              {getTranslations("common_duration", translations)}
            </Text>
            <Text style={{ ...styles.tableCell, ...styles.whiteText }}>
              {getTranslations("student_detail_average", translations)}
            </Text>
          </View>
          <View style={styles.flexAllStudent}>
            {machine.students.map((student) => {
              const average = getStudentAverage(student);
              const className = { ...styles.machineNote, backgroundColor: getNoteColor(average) };
              return (
                <View
                  style={styles.studentContent}
                  key={`${machine.code}-${student.id}`}
                  wrap={false}
                >
                  <View style={styles.studentTitle}>
                    <Text style={styles.tableCell}>{student.name}</Text>
                    <Text style={styles.tableCell}>{student.firstName}</Text>
                    <Text style={styles.tableCell}>
                      {getStudentSimulationTime(student, translations, FORMAT_TIMESTRING.HHMM)}
                    </Text>
                    <View style={styles.tableCell}>
                      <Text style={className}>{getTranslations(getNotePdf(average, notationType), translations)}</Text>
                    </View>
                  </View>

                  <View style={styles.studentSubscriptionTitle}>
                    <Text style={styles.tableCell}>
                      {getTranslations("pdf_result_per_machine_training", translations)}
                    </Text>
                    <Text style={styles.tableCell}>
                      {getTranslations("pdf_result_per_machine_progress", translations)}
                    </Text>
                    <Text style={styles.tableCell}>{getTranslations("common_duration", translations)}</Text>
                    <Text style={styles.tableCell}>{getTranslations("student_detail_average", translations)}</Text>
                  </View>

                  {renderSubscriptionResult(student)}
                </View>
              );
            })}
          </View>
        </View>
      ))}
    </View>
  );
};

export default GroupedPdfContent;
