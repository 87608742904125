//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosDatepickerInput from "../../../Components/AcreosDatePickerInput/AcreosDatePickerInput.component";
import AcreosDatePickerInputRange from "../../../Components/AcreosDatePickerInputRange/AcreosDatePickerInputRange.component";
import AcreosSelect from "../../../Components/AcreosSelect/AcreosSelect.component";
import GstaInput from "../../../Components/GstaInput/GstaInput.component";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { SetDataTestId } from "../../../Helper/DataTestId";
//#endregion

//#region constants import
import { DATEPICKER_TYPE } from "../../../Components/AcreosDatePicker/AcreosDatePicker.constants";
import { INPUT_TEXT } from "../../../Components/GstaInput/GstaInput.constants";
import { CUSTOM_FIELD_DATETIME, CUSTOM_FIELD_LIST, CUSTOM_FIELD_STRING } from "../../../Constants/CustomFieldType";
import {
  STANDARD_FORMAT_STRING_MAX_LENGTH,
  STANDARD_FORMAT_STRING_MIN_LENGTH,
} from "../../../Constants/StringConstants";
import { TRANSLATIONS } from "../../../Constants/Translations";

//#endregion

//#region style import
import "./MainInformationForm.style.scss";
//#endregion

const MainInformationForm = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  password,
  setPassword,
  selectedStartDate,
  setStartSelectedDateTime,
  selectedEndDate,
  setEndSelectedDateTime,
  edditionMode,
  checkValidRequiredField,
  checkValidRequiredFieldLength,
  customFields,
  setCustomFields,
  isValidRequiredDate,
  scrollTarget,
  manageWithDates,
}) => {
  //#region functions import
  const setPasswordWithDigit = (newPassword) => {
    newPassword = newPassword.replace(/\D/g, "").slice(0, 4);
    setPassword(newPassword);
  };
  const translations = useSelector((state) => state.translationSlice.translations);
  const customFieldsDefinitions = useSelector((state) => state.studentSlice.customFields);

  const handleSetCustomFields = (customFieldDefinition, customFieldValue) => {
    const newCustomFields = { ...customFields };
    newCustomFields[customFieldDefinition.name] = {
      id: customFieldDefinition.id,
      name: customFieldDefinition.name,
      value: customFieldValue,
      fieldType: customFieldDefinition.fieldType,
    };
    setCustomFields(newCustomFields);
  };

  const checkCustomFieldValid = (customFieldDefinition) => {
    return !customFieldDefinition.isMandatory || customFields[customFieldDefinition.name]?.value;
  };

  const renderCustomFields = () => {
    const customFieldsForm = [];
    customFieldsDefinitions?.forEach((customFieldDefinition, index) => {
      switch (customFieldDefinition.fieldType) {
        case CUSTOM_FIELD_STRING.value:
          customFieldsForm.push(
            <GstaInput
              key={index}
              id={customFieldDefinition.name}
              errorMessage={getTranslations(TRANSLATIONS.FIELD_IS_REQUIRED, translations)}
              isValid={edditionMode || checkCustomFieldValid(customFieldDefinition)}
              label={customFieldDefinition.name + (customFieldDefinition.isMandatory ? " *" : "")}
              setValue={(newValue) => handleSetCustomFields(customFieldDefinition, newValue)}
              placeholder={getTranslations("personnalized_field_confirm_exemple", translations)}
              value={customFields[customFieldDefinition.name]?.value ?? ""}
              dataTestId={SetDataTestId(customFieldDefinition.name)}
              scrollTarget={scrollTarget}
              type={INPUT_TEXT}
            />
          );
          break;
        case CUSTOM_FIELD_DATETIME.value:
          customFieldsForm.push(
            <AcreosDatepickerInput
              type={DATEPICKER_TYPE.SELECT_DAY}
              overrideKey={index}
              id={customFieldDefinition.name}
              label={customFieldDefinition.name + (customFieldDefinition.isMandatory ? " *" : "")}
              selectedDateTime={
                customFields[customFieldDefinition.name]?.value
                  ? new Date(customFields[customFieldDefinition.name]?.value)
                  : undefined
              }
              setSelectedDateTime={(newValue) => handleSetCustomFields(customFieldDefinition, newValue)}
              styleOverride="acreos-date-picker_input_row acreos-date-picker_input_grey"
              isValid={edditionMode || checkCustomFieldValid(customFieldDefinition)}
              selectRange={false}
              scrollTarget={scrollTarget}
              errorMessage={getTranslations(TRANSLATIONS.FIELD_IS_REQUIRED, translations)}
            />
          );
          break;
        case CUSTOM_FIELD_LIST.value:
          customFieldsForm.push(
            <AcreosSelect
              key={index}
              id={customFieldDefinition.name}
              title={customFieldDefinition.name + (customFieldDefinition.isMandatory ? " *" : "")}
              value={customFields[customFieldDefinition.name]?.value}
              setNewValue={(newValue) => handleSetCustomFields(customFieldDefinition, newValue)}
              options={customFieldDefinition.listValues.map((value) => ({
                value: value.value,
                label: value.value,
              }))}
              defaultText={getTranslations("personnalized_field_grouped_action_choose", translations)}
              addClassCustom={"acreos-select_row acreos-select_grey"}
              isValid={edditionMode || checkCustomFieldValid(customFieldDefinition)}
              errorMessage={getTranslations(TRANSLATIONS.FIELD_IS_REQUIRED, translations)}
              scrollTarget={scrollTarget}
            />
          );
          break;
        default:
          break;
      }
    });
    return customFieldsForm;
  };

  const buildErrorMessage = () => {
    return (
      (!checkValidRequiredFieldLength(firstName, "firstName") ||
        !checkValidRequiredFieldLength(lastName, "lastName")) &&
      `${STANDARD_FORMAT_STRING_MIN_LENGTH} ${getTranslations(
        "sentence_minimum_length",
        translations
      )} ${STANDARD_FORMAT_STRING_MAX_LENGTH} ${getTranslations("personnalized_field_limit_caracters", translations)}`
    );
  };
  //#endregion

  return (
    <div>
      <h2>{getTranslations("student_create_student_general_informations", translations)}</h2>
      <hr />
      <div className="create-student-main-information-form">
        <div>
          <GstaInput
            id={"lastName"}
            label={getTranslations("student_create_student_name", translations)}
            value={lastName}
            setValue={setLastName}
            isValid={edditionMode || checkValidRequiredFieldLength(lastName, "lastName")}
            placeholder={"Ex: Dupont"}
            dataTestId={SetDataTestId("lastName-input")}
            errorMessage={buildErrorMessage()}
            scrollTarget={scrollTarget}
            type={INPUT_TEXT}
            formatValue={(newValue) => newValue.toUpperCase()}
          />
          <GstaInput
            id={"firstName"}
            label={getTranslations("student_create_student_first_name", translations)}
            value={firstName}
            setValue={setFirstName}
            isValid={edditionMode || checkValidRequiredFieldLength(firstName, "firstName")}
            placeholder={"Ex: Marie"}
            dataTestId={SetDataTestId("firstName-input")}
            errorMessage={buildErrorMessage()}
            scrollTarget={scrollTarget}
            type={INPUT_TEXT}
          />
          <GstaInput
            id={"password"}
            label={getTranslations("student_create_student_pin", translations) + " *"}
            value={password}
            setValue={setPasswordWithDigit}
            isValid={edditionMode || (checkValidRequiredField(password) && password.length === 4)}
            placeholder={getTranslations("student_create_student_pin_placeholder", translations)}
            dataTestId={SetDataTestId("password-input")}
            errorMessage={getTranslations("student_create_student_pin_error_message", translations)}
            scrollTarget={scrollTarget}
            type={INPUT_TEXT}
            formatValue={(newPassword) => (newPassword = newPassword.replace(/\D/g, "").slice(0, 4))}
          />
          {renderCustomFields()}
          {manageWithDates && (
            <div className="student-access-dates-pickers">
              <label>
                <b>{getTranslations("session_date_student_profile", translations) + " *"}</b>
              </label>
              <section>
                <AcreosDatePickerInputRange
                  id={"dates"}
                  labelStart={getTranslations("session_date_student_start", translations)}
                  labelEnd={getTranslations("session_date_student_end", translations)}
                  startDate={selectedStartDate}
                  setStartDate={setStartSelectedDateTime}
                  endDate={selectedEndDate}
                  setEndDate={setEndSelectedDateTime}
                  isDateValid={
                    edditionMode || (isValidRequiredDate(selectedEndDate) && isValidRequiredDate(selectedStartDate))
                  }
                  scrollTarget={scrollTarget}
                />
              </section>
            </div>
          )}
        </div>
        <div>
          <p className="create-student-mandatory">{getTranslations(TRANSLATIONS.REQUIRED_FIELD, translations)}</p>
        </div>
      </div>
    </div>
  );
};

export default MainInformationForm;
